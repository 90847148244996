import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Neighbors in Need 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Neighbors-in-Need.png"
          alt="Purple Cow Internet - the herd helping the herd"
          /> 
            <br></br>
            <br></br>
            <h1>The Herd helping the Herd</h1>
  
            <p>At Purple Cow, we’re more than just an internet provider; we’re a community with a mission to make life better for Maritimers. Today, we’re thrilled to officially launch our <strong>Neighbor in Need</strong> program—a unique initiative that gives our community, the Herd, a way to support each other when times get tough. The potential of this program is immense, and we’re excited to see the difference it will make for countless people.</p>
            
            <h2>Why We Created Neighbor in Need</h2>
            
            <p>Life can be unpredictable, and sometimes, a helping hand is all someone needs to stay connected. For many, internet access is critical, from finding resources to staying in touch with family or applying for jobs. Whether it’s a single parent managing expenses, a senior on a fixed income, or anyone facing unexpected challenges, the Neighbor in Need program was created to keep our neighbors connected when it matters most.</p>

            <p>To help launch Neighbor in Need and support those facing hardships, we’re kickstarting the program with a $10,000 donation. We hope this will lay the foundation for a truly impactful initiative that reaches across our community.</p>

            <p>The program invites Herd members to contribute just $2 a month, with every donation matched by Purple Cow dollar for dollar. Together, we’re building a safety net for those who need it most—ensuring that essential internet services remain accessible in times of financial hardship.</p>
            
            <h2>How It Works</h2>
            
            <p>All funds raised through Neighbor in Need go directly to helping cover internet bills for customers facing temporary financial difficulty, as well as supporting vulnerable groups across the Maritimes, including low-income parents, seniors, and individuals experiencing homelessness. By joining forces, we’re creating a community that supports each other and builds stability. Built-in measures ensure the funds reach those in genuine need, keeping the program fair and effective.</p>
            
            <h2>A Testament to the Power of Community Support</h2>
            
            <blockquote>
              <p>"I was struggling to keep up with bills after an unexpected layoff, and having internet was essential for job hunting. Thanks to Purple Cow and the Neighbor in Need program, I was able to stay online and get back on my feet. I can’t thank everyone enough." — Herd Member</p>
            </blockquote>
            
            <h2>How You Can Get Involved</h2>
            
            <p>Neighbor in Need is entirely voluntary and open to all Herd members. By choosing to contribute, you’re joining a movement that makes a meaningful impact. Together, with your support, we can build a stronger, more connected community that provides stability and assistance when it’s needed most.</p>

            <p>If you’re a current Herd member and want to get involved, just give us a call or shoot us a text at <strong>902-800-2660</strong>.</p>
            
            <p>The Neighbor in Need program is more than a fund—it’s a shared commitment to each other and a reminder of the strength we hold as a community. If you’d like to join or learn more, visit our website at <a href="https://purplecowinternet.com/neighbor-in-need/">https://purplecowinternet.com/neighbor-in-need/</a>.</p>
            
            <p>Let’s work together to lift each other up and continue building a better tomorrow.</p>

            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
